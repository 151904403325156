.container {
  background-color: #292929;
  position: relative;
  text-align: right;
  color: white;
  padding: 2rem 3rem;
  -webkit-box-shadow: inset 0px 2px 16px 0px rgba(0, 0, 0, 0.9);
  box-shadow: inset 0px 2px 16px 0px rgba(0, 0, 0, 0.9);
  animation: 1s down-enter;
}

@keyframes down-enter {
  0% {
    clip-path: inset(0 0 100% 0);
  }
  100% {
    clip-path: inset(0);
  }
}

.title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  overflow-wrap: break-word;
}

.content {
  font-size: 1.8rem;
  white-space: pre-line;
}

.edit-button {
  position: absolute;
  left: 1rem;
  top: 1rem;
  width: 3rem;
}

.add-notification-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.add-notification-button {
  font-size: 2rem;
  color: white;
  background-color: black;
  padding: 0.6rem 0.6rem 0.6rem 1.2rem;
  display: inline;
  background-color: #292929;
  border-radius: 10px;
}
