.container {
  background-color: rgb(68, 68, 68);
  height: calc(100vh - 6.5rem);
  padding-top: 2rem;
  box-sizing: border-box;
  overflow: scroll;
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
}

.add-new-section-container {
  flex: 0 0 50%;
  padding: 3rem;
  text-decoration: none;
  box-sizing: border-box;
}

.add-new-section-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  min-height: 16rem;
}

.add-new-section-icon {
  font-size: 8rem;
  color: white;
  background-color: rgb(41, 41, 41);
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: 1.6rem;
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: 0;
}
