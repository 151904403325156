.container {
  padding: 3rem;
  background-color: rgb(68, 68, 68);
  height: calc(100vh - 12.5rem);
  overflow: scroll;
}

.add-category-button-container {
  display: flex;
  border-top: 1px dashed white;
  align-items: center;
  padding-top: 1rem;
  justify-content: center;
}

.add-category-button {
  font-size: 2.4rem;
  color: white;
  background-color: black;
  padding: 0.6rem 0.6rem 0.6rem 1.2rem;
  display: inline;
  background-color: #292929;
  border-radius: 10px;
  margin-top: 1rem;
}
