.section {
  margin-bottom: 5rem;
  margin-top: 1rem;
}

.input {
  height: 3rem;
  width: 70%;
  font-size: 1.6rem;
}

.edit-title {
  width: 2rem;
}

.save-button {
  height: 3rem;
}

.title {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
  color: white;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  text-decoration: underline;
}

.link {
  color: #8ab4f7;
  text-decoration: underline;
}

.section-content {
  margin-top: 1rem;
}

.add-product-button-container {
  display: flex;
}

.add-product-button {
  font-size: 2rem;
  color: white;
  background-color: black;
  padding: 0.6rem 0.6rem 0.6rem 1.2rem;
  display: inline;
  background-color: #292929;
  border-radius: 10px;
  margin-top: 1rem;
}
