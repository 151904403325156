.container {
  background-color: #fb2626;
  color: white;
  position: fixed;
  top: 6.5rem;
  padding: 2rem;
  font-size: 1.7rem;
  border-radius: 10px;
  box-shadow: 0 0 10px black;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}
