.category-container-link {
  flex: 0 0 50%;
  padding: 3rem;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
}

.category-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.label {
  font-size: 1.6rem;
  color: white;
  font-weight: bold;
}

.edit-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3rem;
  left: 3rem;
}

.hidden-category {
  display: none;
}

.grayed-category {
  opacity: 0.3;
}

.image-container {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.category-image {
  width: 100%;
  height: 100%;
  margin-bottom: auto;
  object-fit: cover;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  top: 0;
  left: 0;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}
