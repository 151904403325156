.container {
  background-color: rgb(68, 68, 68);
  height: calc(100vh - 6.5rem);
  padding: 2rem 3rem 0 3rem;
  box-sizing: border-box;
  overflow: scroll;
}

.feature-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.7rem;
  padding: 1rem 0.8rem;
  color: white;
}

.feature-info-container {
  display: flex;
  flex-direction: column;
}

.feature-name {
  font-size: 1.9rem;
  font-weight: bold;
}

.feature-description {
  font-size: 1.7rem;
  margin-top: 0.7rem;
  margin-right: 0.5rem;
  opacity: 0.7;
}
